import {backendApi} from "../../backend-api-sr"

export const downtimeAnalysis = (() =>{
    const fetchDashboardDown = ( async (subPath) => {
        var url = '/v2/sr/down'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })

    const fetchPieChart = ( async (subPath) => {
        var url = '/v2/sr/down/pie_chart'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })

    const fetchDetail = ( async (subPath) => {
        var url = '/v2/sr/down/Detail'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })


    return {fetchDashboardDown, fetchPieChart, fetchDetail};
})()