<template>
    <v-container fluid style="padding-bottom:80px">
        <v-row>
            <v-row>
                <v-col cols="12">
                    <v-breadcrumbs :items="breadcumbs">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item :to="item.href" class="text-subtitle-2 crumb-item" :disabled="item.disabled"
                                exact>
                                {{ item.text }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                    <v-snackbar v-model="snackbar.visible" :color="snackbar.color"
                        :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout"
                        :top="snackbar.position === 'top'">
                        <v-layout align-center pr-4>
                            <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                            <v-layout column>
                                <div>
                                    <strong>{{ snackbar.title }}</strong>
                                </div>
                                <div>{{ snackbar.text }}</div>
                            </v-layout>
                        </v-layout>
                        <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                            <v-icon>clear</v-icon>
                        </v-btn>
                    </v-snackbar>
                </v-col>
            </v-row>

            <v-col cols="12">
                <v-card outlined>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <v-autocomplete solo clearable v-model="machs" :items="mach" item-value="value"
                                    item-text="status" label="Mach Type" class="ma-0 pa-1 border-12" hide-details=true>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <v-autocomplete solo clearable v-model="machId" :items="machIds" item-value="value"
                                    item-text="status" label="Mach Id" class="ma-0 pa-1 border-12" hide-details=true>
                                </v-autocomplete>
                            </v-col>
                            <!-- <v-col cols="12" xs="12" sm="5" md="2">
                                <v-autocomplete solo clearable v-model="type" :items="types" item-value="value"
                                    item-text="status" label="TR Type" class="ma-0 pa-1 border-12" hide-details=true>
                                </v-autocomplete>
                            </v-col> -->
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <v-menu ref="modal" v-model="modal" :close-on-content-click="false"
                                    transition="scale-transition" offset-y max-width="290px" min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field solo v-model="date_from" label="Start Periode" persistent-hint
                                            prepend-inner-icon="mdi-calendar" v-bind="attrs" v-on="on"
                                            class="ma-0 pa-1 border-12" hide-details=true></v-text-field>
                                    </template>
                                    <v-date-picker v-model="date_from" type="month" no-title
                                        @input="modal = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <v-menu ref="modal_to" v-model="modal_to" :close-on-content-click="false"
                                    transition="scale-transition" offset-y max-width="290px" min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field solo v-model="date_to" label="End Periode" persistent-hint
                                            prepend-inner-icon="mdi-calendar" v-bind="attrs" v-on="on"
                                            class="ma-0 pa-1 border-12" hide-details=true></v-text-field>
                                    </template>
                                    <v-date-picker v-model="date_to" type="month" no-title
                                        @input="modal_to = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" xs="12" sm="2" md="1">
                                <v-btn block class="border-12 mt-1" style="padding: 19px;" small color="primary"
                                    elevation="2" @click="getPullData()"><v-icon
                                        class="mr-1">mdi-magnify</v-icon>Search</v-btn>
                            </v-col>
                            <v-col cols="12" xs="12" sm="2" md="1">
                                <v-btn block class="border-12 mt-1" style="padding: 19px;" small color="#FFC107"
                                    elevation="2">
                                    <v-icon>mdi-file-excel</v-icon>
                                    <download-excel class="text" :fetch="exportExcel" :fields="headersColumn"
                                        :before-generate="startDownload" :before-finish="finishDownload">
                                        Export
                                    </download-excel> </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>

            <v-col cols="12" class="mt-3">
                <v-row>
                    <v-card outlined class="pa-md-5">
                        <v-card-text>
                            <h6 class="cyan-text text-darken-3 m-0"> Downtime Summary (Period to Period)</h6>
                            <v-img class="text-center mt-5 mx-auto" lazy-src="@/assets/empty_data.png" max-width="300"
                                src="@/assets/empty_data.png" v-if="showData"></v-img>
                            <div id="container1" style="height: 370px; width: 100%;"></div>
                        </v-card-text>
                    </v-card>
                </v-row>

                <v-row class="mt-5">
                    <v-col cols="12" xs="12" sm="6" md="4">
                        <v-card outlined height="400">
                            <v-card-text>
                                <v-img
                                    class="text-center mx-auto"
                                    style="margin-top: 80px;"
                                    lazy-src="@/assets/empty_data.png"
                                    max-width="200"
                                    src="@/assets/empty_data.png"
                                    v-if="showDataDetail">
                                </v-img>
                                <div id="container2" style="height: 370px; width: 100%;"></div>
                            </v-card-text> 
                        </v-card>
                    </v-col>

                    <v-col cols="12" xs="12" sm="6" md="4">
                        <v-card outlined height="400">
                            <v-card-text>
                                <v-img
                                    class="text-center mx-auto"
                                    style="margin-top: 80px;"
                                    lazy-src="@/assets/empty_data.png"
                                    max-width="200"
                                    src="@/assets/empty_data.png"
                                    v-if="showDataDetail">
                                </v-img>
                                <div id="container3" style="height: 370px; width: 100%;"></div>
                            </v-card-text> 
                        </v-card>
                    </v-col>
                    <v-col cols="12" xs="12" sm="6" md="4">
                        <v-card outlined height="400">
                            <v-card-text>
                                <v-img
                                    class="text-center mx-auto"
                                    style="margin-top: 80px;"
                                    lazy-src="@/assets/empty_data.png"
                                    max-width="200"
                                    src="@/assets/empty_data.png"
                                    v-if="showDataDetail">
                                </v-img>
                                <div id="container4" style="height: 370px; width: 100%;"></div>
                            </v-card-text> 
                        </v-card>
                    </v-col>
                </v-row>

                <v-row class="mt-2" v-if="tableVisible">
                    <v-col class="col-12">
                        <v-card outlined>
                            <v-card-title>
                                <h6 class="cyan-text text-darken-3 m-0">Detail</h6>
                            </v-card-title>
                            <v-container fluid>
                                <v-row>
                                    <v-col cols="12">
                                        <v-data-table :headers="header" :items="details" :items-per-page="20" fixed-header
                                            height="450" :item-class="tr_datatable" :divider="true" :light="true">
                                            <!-- <template v-slot:detailData.wgt="{ item }">
                                    {{ formatNumber(item.wgt) }}
                                </template> -->
                                        </v-data-table>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {downtimeAnalysis} from "../../../backend-api/sr/mill_data_analysis/downtime_analysis"

export default {
    data() {
        return {
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            breadcumbs: [
                {
                    text: 'Sunrise Steel',
                    disabled: false,
                    href: '/admin/sr',
                },
                {
                    text: 'Mill Data Analysis',
                    disabled: true,
                    href: 'breadcrumbs_link_2',
                },
                {
                    text: 'Downtime Summary',
                    disabled: true,
                    href: 'breadcrumbs_link_2',
                },
            ],
            tab: 'tab-1',
            speed: null,
            dashboardContent: null,
            offices: [{
                office_id: 'Sunrise Steel',
                office: 'Sunrise Steel'
            }],
            mach: [
                {
                    status: 'GL',
                    value: 'GL'
                },
                {
                    status: 'SL',
                    value: 'SL'
                }
            ],
            machIds: [
                {
                    status: '01',
                    value: '01'
                },
                {
                    status: '02',
                    value: '02'
                }
            ],
            types: [
                {
                    status: 'PLAN',
                    value: 'PLAN'
                },
                {
                    status: 'UNPLAN',
                    value: 'UNPLAN'
                }

            ],
            machs: '',
            machId: '',
            type: '',
            office: 'Sunrise Steel',
            tab: 'tab-1',
            tab: 'tab-1',
            date_from: '',
            modal: false,
            date_to: '',
            chartContainer1: [],
            modal_to: false,
            tableVisible: false,
            speed: null,
            dashboardContent: null,
            x: window.matchMedia("(max-width: 991px)"),
            months: [
                'Jan', 'Feb', 'Mar', 'Apr', 'May',
                'Jun', 'Jul', 'Aug', 'Sep',
                'Oct', 'Nov', 'Dec'
            ],
            subyear: '',
            year: '',
            reson: '',
            showData: true,
            details: [],
            resons: [],
            header: [
                {
                    text: 'MACH TYPE',
                    align: 'start',
                    value: 'mach_type',
                    align: 'left', class: 'amber --text amber '
                },
                { text: 'MACH ID', value: 'mach_id', align: 'left', class: 'amber --text amber' },
                { text: 'TR TYPE', value: 'tr_type', align: 'left', class: 'amber --text amber ' },
                { text: 'DEPARTEMEN', value: 'maint_dept', align: 'left', class: 'amber --text amber ' },
                { text: 'REASON', value: 'descr', align: 'left', class: 'amber --text amber ' },
                { text: 'REMARK', value: 'remark', align: 'left', class: 'amber --text amber ' },
                { text: 'Start Downtime', value: 'start_down', align: 'left', class: 'amber --text amber ' },
                { text: 'Finish Downtime', value: 'finish_down', align: 'left', class: 'amber --text amber ' },
                { text: 'Downtime Duration', value: 'min_down', align: 'left', class: 'amber --text amber ' },
            ],
            headersColumn: {
                'Mach Type': 'mach_type',
                'Mach ID': 'mach_id',
                'Tr Type': 'tr_type',
                'Departemen': 'maint_dept',
                'Reason': 'descr',
                'Remark': 'remark',
                'Start Downtime': 'start_down',
                'Finish Downtime': 'finish_down',
                'Downtime Duration': 'min_down'
            },
            showDataDetail:true,
        }
    },
    async mounted() {
        // this.GetDashboard()
        this.$store.dispatch('setOverlay', false)
    },
    methods: {
        tr_datatable() {
            const rowClass = 'tr_datatable'
            return rowClass;
        },
        async getPullData() {
            if (this.date_from === '' || this.date_from === null) {
                this.snackbar = {
                    color: "warning",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Whoooopsss !",
                    text: "Insert Period",
                    visible: true
                };
                return false;
            }
            this.$store.dispatch('setOverlay', true)
            var start_date = this.date_from ? new Date(this.date_from).toISOString().substr(0, 8).replace(/-/g, "") : ''
            var end_date = this.date_to ? new Date(this.date_to).toISOString().substr(0, 8).replace(/-/g, "") : ''
            const respData = await downtimeAnalysis.fetchDashboardDown(`?start=${start_date ? start_date : ""}&end=${end_date ? end_date : ""}&mach_type=${this.machs ? this.machs : ""}&mach_id=${this.machId ? this.machId : ""}&tr_type=${this.type ? this.type : ""}`)

            if (respData.status == 200) {
                var container = "container1";
                var inputJson = respData.data.data;
                this.showData = false;
                const transformedData = {};
                inputJson.forEach(item => {
                    const trType = item.tr_type.toLowerCase();
                    if (!transformedData[trType]) {
                        transformedData[trType] = [];
                    }
                    transformedData[trType].push({
                        "label": item.periode,
                        "y": parseFloat(item.total_menit),
                        tr_type: item.tr_type,
                    });
                });
                var plan = (transformedData.plan) ? transformedData.plan : []
                var unplan = (transformedData.unplan) ? transformedData.unplan : []
                this.$store.dispatch('setOverlay', false)
                this.getChart(plan, unplan, container);
            } else {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Warning",
                    text: 'Please retry',
                    visible: true
                };
                this.$store.dispatch('setOverlay', false)
                return false
            }
        },

        async exportExcel() {
            if (this.date_from === '') {
                this.snackbar = {
                    color: "warning",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: "Please fill period",
                    visible: true
                };
                return false;
            } else {
                var start_date = this.date_from ? new Date(this.date_from).toISOString().substr(0, 8).replace(/-/g, "") : ''
                var end_date = this.date_to ? new Date(this.date_to).toISOString().substr(0, 8).replace(/-/g, "") : ''
                const response = await axios.get(`${process.env.VUE_APP_URL}/v2/sr/down/GetExcel?start=${start_date ? start_date : ""}&end=${end_date ? end_date : ""}&mach_type=${this.machs ? this.machs : ""}&mach_id=${this.machId ? this.machId : ""}&tr_type=${this.type ? this.type : ""}`, {
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` }
                });
                console.log(response.data);
                return response.data.data

            }

        },
        startDownload() {
            if (this.date_from === '') {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: "Please fill period",
                    visible: true
                };
                return false;
            } else {
                this.$store.dispatch('setOverlay', true)
            }
        },
        finishDownload() {
            // this.loading = false
            // this.disabledExport=false
            this.$store.dispatch('setOverlay', false)
        },
        async GetDetail(e) {
            this.$store.dispatch('setOverlay', true)
            console.log('label', e)
            const respDataPie = await downtimeAnalysis.fetchPieChart(`?mach_type=${this.machs ? this.machs : ""}&mach_id=${this.machId ? this.machId : ""}&tr_type=${e.dataPoint.tr_type}&period=${e.dataPoint.label}`)

            const respDataDetail = await downtimeAnalysis.fetchDetail(`?mach_type=${this.machs ? this.machs : ""}&mach_id=${this.machId ? this.machId : ""}&tr_type=${e.dataPoint.tr_type}&period=${e.dataPoint.label}`)

            // var param = (new Date(new Date(e.dataPoint.x) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 7).replace(/-/g,"");
            var label_detail = "Periode: "+e.dataPoint.label;

            if (respDataPie.status === 200 && respDataDetail.status === 200) {
                this.showDataDetail = false
                this.details = respDataDetail.data.data
                this.tableVisible = true
                this.$store.dispatch('setOverlay', false)
                for (var i = 0; i < respDataPie.data.data.mach_data.length; i++) {
                    var dt_detail =  [];
                    for (var i = 0; i < respDataPie.data.data.mach_data.length; i++) {
                        dt_detail.push({  label: respDataPie.data.data.mach_data[i].mach,  y: parseInt(respDataPie.data.data.mach_data[i].min_down), legendText: respDataPie.data.data.mach_data[i].mach });
                    }
                    var container_detail = 'container2';
                    var title_detail = 'by Machine Type'
                    this.getPieChart(dt_detail, container_detail, title_detail, label_detail)
                }
                for (var i = 0; i < respDataPie.data.data.maint_dept_data.length; i++) {
                    var dt_detail =  [];
                    for (var i = 0; i < respDataPie.data.data.maint_dept_data.length; i++) {
                        dt_detail.push({  label: respDataPie.data.data.maint_dept_data[i].maint_dept,  y: parseInt(respDataPie.data.data.maint_dept_data[i].min_down), legendText: respDataPie.data.data.maint_dept_data[i].maint_dept });
                    }
                    var container_detail = 'container3';
                    var title_detail = 'by Maint Dept'
                    this.getPieChart(dt_detail, container_detail, title_detail, label_detail)
                }
                for (var i = 0; i < respDataPie.data.data.maint_reason_data.length; i++) {
                    var dt_detail =  [];
                    for (var i = 0; i < respDataPie.data.data.maint_reason_data.length; i++) {
                        dt_detail.push({  label: respDataPie.data.data.maint_reason_data[i].descr,  y: parseInt(respDataPie.data.data.maint_reason_data[i].min_down), legendText: respDataPie.data.data.maint_reason_data[i].descr });
                    }
                    var container_detail = 'container4';
                    var title_detail = 'by Maint Reason'
                    this.getPieChart(dt_detail, container_detail, title_detail, label_detail)
                }
            } else {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Warning",
                    text: 'Please retry',
                    visible: true
                };
                this.$store.dispatch('setOverlay', false)
                return false
            }
        },

        getChart(datapoints1, datapoints2, container) {
            var chart = new CanvasJS.Chart(container, {
                animationEnabled: true,
                theme: "light2",
                backgroundColor: "transparent",
                exportEnabled: true,
                axisY: {
                    title: "Ton",
                    crosshair: {
                        enabled: true,
                        snapToDataPoint: true
                    },
                    labelFormatter: this.addSymbols,
                },
                toolTip: {
                    shared: true,
                    yValueFormatString: "##0.00",
                },
                legend: {
                    cursor: "pointer",
                    itemclick: this.toggleDataSeries
                },
                data: [
                    {
                        click: this.GetDetail,
                        type: "column",
                        indexLabel: "{y}",
                        indexLabelFontSize: 14,
                        indexLabelFontColor: "#FFF",
                        name: 'UNPLAN',
                        showInLegend: true,
                        indexLabelPlacement: "inside",
                        indexLabelOrientation: "vertical",
                        // color: "#F29605",
                    },
                    {
                        click: this.GetDetail,
                        type: "column",
                        indexLabel: "{y}",
                        indexLabelFontSize: 14,
                        indexLabelFontColor: "#FFF",
                        name: 'PLAN',
                        showInLegend: true,
                        indexLabelPlacement: "inside",
                        indexLabelOrientation: "vertical",
                        // color: "#CF8D25",
                    }
                ]
            });

            chart.options.data[0].dataPoints = datapoints2;
            chart.options.data[1].dataPoints = datapoints1;

            if (this.x.matches) {
                for (var i = 0; i < chart.options.data.length; i++) {
                    chart.options.data[i].indexLabelFontSize = 8;
                }
            }
            chart.render();
        },

        getPieChart(dt_detail, container_detail, title_detail, label_detail, destroy = false){
            var pie_chart = new CanvasJS.Chart(container_detail, {
                animationEnabled: true,
                exportEnabled: true,
                theme: "light2",
                exportEnabled: true,
                title: {
                    text: title_detail,
                    fontFamily: "Calibri",
                    fontSize: 20
                },
                exportEnabled: true,
                subtitles:[
                    {
                        text: label_detail,
                        //Uncomment properties below to see how they behave
                        fontFamily: "Calibri",
                        fontColor: "red",
                        fontSize: 12
                    }
                ],
                legend: {
                    itemclick: this.toggleDataPointVisibility
                },
                data: [{

                    type: "pie",
                    percentFormatString: "#0.##",
                    indexLabel: "{label} #percent%",
                    indexLabelFontSize: 12,

                }]
            });

            pie_chart.options.data[0].dataPoints = dt_detail;
            // this.showDefaultText(pie_chart, "No Data Found!");
            var x = window.matchMedia("(max-width: 991px)");

            if (x.matches) {

                for(var i = 0; i < pie_chart.options.data.length; i++){
                    pie_chart.options.data[i].indexLabelFontSize = 6;
                }
                pie_chart.render();
            }
            pie_chart.render();

            if (destroy == true) {
                pie_chart.destroy();
            }
        },

        toggleDataPointVisibility(e) {
            if(e.dataPoint.hasOwnProperty("actualYValue") && e.dataPoint.actualYValue !== null) {
                e.dataPoint.y = e.dataPoint.actualYValue;
                e.dataPoint.actualYValue = null;
                e.dataPoint.indexLabelFontSize = null;
                e.dataPoint.indexLabelLineThickness = null;
                e.dataPoint.legendMarkerType = "circle";
            } 
            else {
                e.dataPoint.actualYValue = e.dataPoint.y;
                e.dataPoint.y = 0;
                e.dataPoint.indexLabelFontSize = 0;
                e.dataPoint.indexLabelLineThickness = 0; 
                e.dataPoint.legendMarkerType = "cross";
            }
                e.chart.render();
        },

        toggleDataSeries(e) {
            if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
                e.dataSeries.visible = false;
            } else {
                e.dataSeries.visible = true;
            }
            e.chart.render();
        },
    },
}
</script>
<style scoped>
.tr_datatable {
    background-color: #F5F7F8 !important;
}
</style>
